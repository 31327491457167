<template>
    <main>
        <a href="/" class="signup-logo">
            <h1>MailPal</h1>
        </a>
        <div class="half left">
            <div v-if="status === 'unauthorized'" class="content-wrapper">
                <h1>Create your account</h1>
                <div class="content-box">
                    <p>Your are almost there!</p>
                    <div class="form">
                        <label for="email">Email address:</label>
                        <input placeholder="richard@piedpiper.com" type="email" autocomplete="email" v-model="email" />
                        <span><font-awesome-icon icon="question" class="form-info-icon"/>Used for login and email forwarding</span>
                    </div>
                    <div class="form">
                        <label>Password:</label>
                        <div class="info-box">
                            <p>At MailPal, there are no passwords. Each time you want to login to your account, we send you a temporary code which you will have to enter on the login page or click the link included in the email. You can read more about why we don't have passwords <a href="/docs#passwordless">here.</a></p>
                        </div>
                    </div>
                    <button class="primary-button signup-button" @click="signUp">Next step<font-awesome-icon icon="arrow-right" class="signup-arrow"/></button>
                </div>
                <p id="login">Already have an account? - <a href="login">Login!</a></p>
            </div>
            <div v-else-if="status === 'verify'" class="content-wrapper">
                <h1>Verify your email address</h1>
                <div class="content-box">
                    <p>We've sent you an email to verify your address. Either click on the link in the email, or enter the code:</p>
                    <div class="form">
                        <label for="code">Code:</label>
                        <input v-model="code" placeholder="abcdef" autocomplete="one-time-code">
                    </div>
                    <button class="primary-button signup-button" @click="checkCode">Next step<font-awesome-icon icon="arrow-right" class="signup-arrow"/></button>
                </div>
            </div>
            <div v-else-if="status === 'subscription'" class="content-wrapper">
                <h1>Start your 14 day trial</h1>
                <div class="content-box">
                    <p>We will only charge you after 14 days. You can cancel the subscription at any time</p>
                    <div class="form">
                        <label for="code">Credit Card:</label>
                        <input v-model="card" placeholder="1234-1234-1234" autocomplete="credit-card">
                        <span><font-awesome-icon icon="question" class="form-info-icon"/>Payment handled securely via Stripe</span>
                    </div>
                    <button class="primary-button signup-button" @click="startTrial">Start trial<font-awesome-icon icon="arrow-right" class="signup-arrow"/></button>
                </div>
            </div>
            <div v-else-if="status === 'success'" class="content-wrapper">
                <h1>Successfull sign up!</h1>
                <p>If you are not redirected after 5s, click <a href="/">here</a></p>
            </div>
            <div v-else-if="status === 'loading'" class="loading">
                <LoadingIcon />
            </div>
            <div v-else class="content-wrapper">
                <h1>An error occurred</h1>
                <p>{{errorMsg}}</p>
                <a href="/signup">Reload page</a>
            </div>
        </div>
        <div class="half right">
            <div class="side-head">
                <h2>Protect your Privacy and Inbox with <span>MailPal</span></h2>
            </div>
            <img src="/static/images/brief.png">
            <div v-if="status !== 'success'" class="stage">
                <div class="stage-item" :class="{ 'current': status === 'unauthorized' }">
                    <span></span>
                    <p>Account</p>
                </div>
                <div class="stage-item" :class="{ 'current': status === 'verify' }">
                    <span></span>
                    <p>Verify</p>
                </div>
                <div class="stage-item" :class="{ 'current': status === 'subscription' }">
                    <span></span>
                    <p>Subscription</p>
                </div>
                <span id="bgLine"></span>
            </div>
        </div>
    </main>
</template>

<script>
    import { Action, Mutation } from '../store/auth/types'
    import LoadingIcon from '@/components/LoadingIcon'

    export default {
        name: 'SignUp',
        data (){
            return {
                subscription: "free",
                card: ""
            }
        },
        computed: {
            email: {
                get: function(){
                    return this.$store.state.auth.email;
                },
                set: function(value){
                    this.$store.commit(Mutation.UPDATE_EMAIL, value); 
                }
            },
            code: {
                get: function(){
                    return this.$store.state.auth.code;
                },
                set: function(value){
                    this.$store.commit(Mutation.UPDATE_CODE, value); 
                }
            },
            status: function(){
                return this.$store.state.auth.status;
            },
            errorMsg: function(){
                return this.$store.state.auth.errorMsg;
            }
        },
        methods: {
            signUp(){
                this.$store.dispatch(Action.SIGN_UP);
            },
            checkCode(){
                this.$store.dispatch(Action.CHECK_CODE, "signup")
                .then(() => {
                    this.$router.replace({ path: '/' })
                })
            },
            backHome(){
                window.location.href = 'https://mailpal.cc'
            }
        },
        components: {
            LoadingIcon
        },
        created(){
            document.body.parentNode.style.background = "var(--background)";
            if(this.$store.getters.isLoggedIn){
                this.$router.replace('/')
            }
        }
    }
</script>

<style>
    html,
    body {
        color: var(--font);
        background: var(--background);
    }  

    .signup-logo{
        position: absolute;
        top: 5px;
        left: 30px;
        cursor: pointer;
        text-decoration: none;
        color: var(--font);
        z-index: 1;
    }

    .half{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        position: relative;
    }

    .left{
        float: left;
        background: var(--background-sidemenu);
    }

    .right{
        float: right;
    }

    .right img{
        max-width: 300px;
    }

    .side-head span{
        color: var(--primary);
    }

    .stage{
        width: 500px;
        display: flex;
        justify-content: space-around;
        position: absolute;
        bottom: 50px;
    }

    .stage-item{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .stage-item p{
        color: var(--font-dark)
    }

    .stage-item span{
        width: 20px;
        height: 20px;
        display: block;
        background: var(--background-sidemenu);
        border-radius: 100%;
        border: 4px solid var(--background-sidemenu);
    }

    .current span{
        border: 4px solid var(--primary);
    }

    .current p{
        color: var(--font);
    }

    #bgLine{
        position: absolute;
        width: 330px;
        height: 3px;
        background: var(--background-sidemenu);
        top: 12px;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
    }

    .content-wrapper{
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content-wrapper h1{
        font-size: 35px;
        margin-bottom: 0;
    }

    .content-wrapper p{
        margin-top: 10px;
        color: var(--font-light);
        font-size: 18px;
        text-align: center;
    }

    .content-wrapper a{
        color: var(--primary);
        text-decoration: none;
    }

    .content-box{
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #login{
        position: absolute;
        bottom: 20px;
    }

    .form{
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 1rem;
        width: 100%;
    }

    .form label{
        font-size: 18px;
    }

    .form input{
        border: none;
        border-radius: 5px;
        background: var(--background);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font);
        font-weight: 700;
        padding: 15px 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .form input:focus{
        filter: brightness(105%);
    }

    ::placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    },

    :-ms-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    .form span{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .form-info-icon{
        margin-right: 5px;
    }
    
    .info-box{
        border-radius: 5px;
        background: var(--background);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        padding: 15px 15px;
        margin-top: 10px;
    }

    .info-box p{
        margin: 0;
        color: var(--font-light);
        font-size: 14px;
        text-align: left;
    }

    .info-box a{
        color: var(--primary);
        text-decoration: none;
    }

    .signup-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        margin-top: 1rem;
    }

    .signup-arrow{
        margin-left: 7px;
        margin-right: -5px;
    }

    .loading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    /* ______________________________________ 

    .signup-content{
        width: 400px;
        background: var(--background-sidemenu);
        border-radius: 5px;
        margin: auto;
        padding: 20px 40px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    }

    .signup{
       transition: all 1s fade;
    }

    .verify{
        text-align: center;
    }

    .verify p{
        font-weight: 650;
        font-size: 18px;
    }

    .verify-button{
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        display: block;
        margin: auto;
        margin-top: 1rem;
    }

    .verify input{
        border: none;
        border-radius: 5px;
        background: var(--background);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font);
        font-weight: 700;
        padding: 15px 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .verify span{
        color: var(--primary);
        font-weight: 700;
    }

    .verify a{
        text-decoration: none;
        color: var(--primary);
    }

    .headline{
        text-align: center;
    }

    .headline h1{
        margin-bottom: 0;
    }

    .headline p{
        color: var(--font-dark);
        font-weight: 700px;
        font-size: 18px;
        margin-top: 0;
    }

    .form{
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 1rem;
    }

    .form label{
        font-size: 18px;
    }

    .form input{
        border: none;
        border-radius: 5px;
        background: var(--background);
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
        color: var(--font);
        font-weight: 700;
        padding: 15px 15px;
        margin-top: 10px;
        font-size: 15px;
    }

    .form input:focus{
        filter: brightness(105%);
    }

    ::placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    ::-webkit-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    },

    :-ms-input-placeholder {
        color: var(--font-dark);
        font-weight: 700;
    }

    .form span{
        font-size: 12px;
        color: var(--font-light);
        margin-left: 5px;
        margin-top: 5px;
    }

    .form-info-icon{
        margin-right: 5px;
    }

    .submit{
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
    }

    .submit button{
        align-self: center;
    }

    .submit a{
        align-self: center;
        color: var(--font-light);
        text-decoration: none;
        font-size: 13px;
        margin-top: 0.6rem;
    }

    .submit a:hover{
        text-decoration: underline;
    }

    .primary-button {
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    .primary-button:active {
        transform: scale(0.95);
    }

    .signup-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 19px;
        border-bottom: 5px solid #63C6B1;
        margin-top: 1rem;
    }

    .signup-arrow{
        margin-left: 7px;
        margin-right: -5px;
    }

    .loading{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .subscription p{
        font-size: 18px;
    }

    .subscription-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .subscription-item-select{
        display: flex;
        align-items: center;
        background: var(--background-accent);
        padding: 10px 10px;
        border-radius: 5px;
        width: 120px;
        height: 45px;
        cursor: pointer;
        border: 2px solid var(--background-accent);
    }

    .selected{
        border: 2px solid var(--primary);
    }

    .subscription-item-select p{
        margin: 0;
        color: var(--font-light);
    }

    .subscription-item-select span{
        margin-left: auto;
        color: var(--primary);
        font-weight: 700;
    }

    .subscription-item-select-price{
        margin-left: auto;
    }

    .subscription-item-select-price p{
        font-size: 12px;
        margin: 0;
        color: var(--font);
    }

    @media only screen and (max-width: 500px) {
        .signup-content{
            width: 80%;
            top: 50%;
        }

        .signup-logo{
            display: none;
        }

        .form{
            width: 90%;
        }

        .verify p{
            font-size: 15px;
        }

        .verify h1{
            font-size: 22px;
        }
    }

    */

</style>